<template>
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 5.8335C11.25 9.91683 6 13.4168 6 13.4168C6 13.4168 0.75 9.91683 0.75 5.8335C0.75 4.44111 1.30312 3.10575 2.28769 2.12119C3.27226 1.13662 4.60761 0.583496 6 0.583496C7.39239 0.583496 8.72774 1.13662 9.71231 2.12119C10.6969 3.10575 11.25 4.44111 11.25 5.8335Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 7.5835C6.9665 7.5835 7.75 6.79999 7.75 5.8335C7.75 4.867 6.9665 4.0835 6 4.0835C5.0335 4.0835 4.25 4.867 4.25 5.8335C4.25 6.79999 5.0335 7.5835 6 7.5835Z"
      stroke="currentColor"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
